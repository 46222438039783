import calculatePizzaPrice from './calculatePizzaPrice';

export default function calculateOrderTotal(order, pizzas) {
  // reduce is when you take an array and reduce it down to something else. In this case we will take an array of orders and reduce it down to a single number. second argument of a reduce function is what you start with
  return order.reduce((runningTotal, singleOrder) => {
    const pizza = pizzas.find(
      (singlePizza) => singlePizza.id === singleOrder.id
    );
    return runningTotal + calculatePizzaPrice(pizza.price, singleOrder.size);
  }, 0);
  // loop over each item in the order
  // calculate the total for that pizza
  // add that total to the running total
}
